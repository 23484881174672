import React from 'react'
import { Link } from 'gatsby'
import styles from './about.module.sass'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

import Layout from '../components/layout'
import SEO from '../components/seo'

const AboutPage = () => (
   <Layout>
      <SEO title="About Us" />
      <div className={['page', styles.page__about].join(' ')}>
         <div className={['page--header', styles.page__header].join(' ')}>
            <h1 className={['page--title', styles.page__title].join(' ')}>
               About Us
            </h1>
            {/* <p
               className={['page--subheader', styles.page__subheader].join(
                  ' '
               )}>
            </p> */}
         </div>

         <div className={styles.about__content}>
            <section
               className={[
                  'section',
                  styles.section,
                  styles.section__intro,
               ].join(' ')}>
               <p>
                  We believe clients deserve both exceptional service and
                  professional performance, regardless of status or position.
                  They also deserve simple solutions for their financial future
                  rather than complex and confusing jargon.
               </p>
            </section>

            <section
               className={[
                  'section',
                  styles.section,
                  styles.section__about,
               ].join(' ')}>
               <p>
                  <b>
                     SPC Financial<sup>&reg;</sup> is more than a financial
                     planning firm.
                  </b>{' '}
                  At SPC, we hold our motto of{' '}
                  <em>
                     Finance on a Human Level<small>&reg;</small>
                  </em>{' '}
                  deep in our core. That means we are equipped with a
                  specialized, multi-disciplinary, tax-integrated
                  <Link to="/legal/disclaimers#tax">*</Link>, human-centric
                  team, ready to provide simple solutions to complex financial
                  problems.
               </p>
            </section>

            <section
               className={['section', styles.section, styles.section__two].join(
                  ' '
               )}>
               <div className={styles.mission}>
                  <div
                     className={[
                        'section--header',
                        styles.section__header,
                     ].join(' ')}>
                     <h2
                        className={[
                           'section--title',
                           styles.section__title,
                        ].join(' ')}>
                        Our Mission
                     </h2>
                  </div>
                  <p>
                   Provide financial expertise on decisions, however small, to empower our clients to achieve their financial goals.
                  </p>
               </div>
               <div className={styles.vision}>
                  <div
                     className={[
                        'section--header',
                        styles.section__header,
                     ].join(' ')}>
                     <h2
                        className={[
                           'section--title',
                           styles.section__title,
                        ].join(' ')}>
                        Our Vision
                     </h2>
                  </div>
                  <p>
                     We pride ourselves on the ability to be large enough to
                     provide wealth management + tax-efficient investment
                     services required by our clients, yet small enough where we
                     know them personally.
                  </p>
               </div>
            </section>

            <section
               className={[
                  'section',
                  styles.section,
                  styles.section__about,
               ].join(' ')}>
               <h2>Who have we assisted?</h2>
               <p>
                  <b>We are pleased to have assisted over 2,000 families</b>{' '}
                  pursue their unique wealth management, estate and tax planning
                  goals.
               </p>
               <p>
                  We strive to share our financial vision, wisdom, and tax
                  experience with a multitude of diverse clients:
               </p>
               <ul>
                  <li>CEOs, private business groups, foundations</li>
                  <li>attorneys, executives, entrepreneurs</li>
                  <li>independent professionals, military families</li>
                  <li>physicians, health care professionals</li>
                  <li>federal + state government employees</li>
                  <li>architects, builders</li>
                  <li>widows, widowers, retirees, unmarried couples</li>
                  <li>parents, grandparents, budding young families</li>
               </ul>
               <p>
                  We have also worked with clients, their children, and, in some
                  cases, even their grandchildren.
               </p>
            </section>

            <section
               className={['section', styles.section, styles.section__two].join(
                  ' '
               )}>
               <div className={styles.mission}>
                  <div
                     className={[
                        'section--header',
                        styles.section__header,
                     ].join(' ')}>
                     <h2
                        className={[
                           'section--title',
                           styles.section__title,
                        ].join(' ')}>
                        Our Services
                     </h2>
                  </div>
                  <p>
                     We are proud to offer a wide array of white-glove services
                     to our loyal clients, from Asset Allocation and
                     Income/Estate Tax Planning to College Funding and a
                     complimentary Second Opinion.
                  </p>
                  <br />
                  <Link
                     to="/services"
                     className={[
                        styles.button,
                        'button',
                        'button--small',
                        'button--primary',
                     ].join(' ')}>
                     Be Our Guest <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </Link>
               </div>
               <div className={styles.vision}>
                  <div
                     className={[
                        'section--header',
                        styles.section__header,
                     ].join(' ')}>
                     <h2
                        className={[
                           'section--title',
                           styles.section__title,
                        ].join(' ')}>
                        Our Team
                     </h2>
                  </div>
                  <p>
                     We encourage you to meet our human-centric team of wealth
                     management and tax-integrated advisors.
                     <Link to="/legal/disclaimers#tax">*</Link>
                  </p>
                  <br />
                  <Link
                     to="/team"
                     className={[
                        styles.button,
                        'button',
                        'button--small',
                        'button--primary',
                     ].join(' ')}>
                     Meet Our Team{' '}
                     <FontAwesomeIcon icon={faLongArrowAltRight} />
                  </Link>
               </div>
            </section>

            <section
               className={[
                  'section',
                  styles.section,
                  styles.section__about,
               ].join(' ')}>
               <h2>What are our investment programs?</h2>
               <p>
                  Our investment programs are individually designed to help
                  address your financial needs, while empowering you with the
                  appropriate knowledge necessary to make long-term investment
                  decisions.
               </p>
            </section>

            <section
               className={[
                  'section',
                  styles.section,
                  styles.section__about,
               ].join(' ')}>
               <h2>What is our planning program?</h2>
               <p>
                  Our "Finance on a Human Level<small>&reg;</small>" planning
                  program will help guide you on multiple levels of financial
                  interest, while integrating estate and tax provisions into
                  your financial program, through invaluable resources provided
                  by our CPA<Link to="/legal/disclaimers#tax">*</Link> advisory
                  team.
               </p>
            </section>

            <section
               className={[
                  'section',
                  styles.section,
                  styles.section__about,
               ].join(' ')}>
               <h2>Our Foundation</h2>
               <p>
                  William E. Chatfield (Bill) recognized the complexity in an
                  evolving financial world, dedicating himself in 1977 to
                  becoming one of the first CERTIFIED FINANCIAL PLANNER&trade;
                  professionals in the Washington, D.C. area.
               </p>
               <p>
                  In 1983, Bill met <Link to="/team/edward-sella">Geoff</Link>,
                  a gifted financial visionary and the two became associates and
                  later partners.
               </p>
               <p>
                  Geoff's CPA experience coupled with his CERTIFIED FINANCIAL
                  PLANNER&trade; and Personal Financial Planning knowledge,
                  added to the firm's investment management and tax integration
                  skills. Bill and Geoff worked together up until Bill's
                  retirement in 2000.
               </p>
               <p>
                  Geoff's client dedication and perseverance launched the
                  practice from a small local firm into a nationally-recognized,
                  award-winning, wealth management practice. The firm is now
                  large enough to help meet the needs of most clients while
                  small enough to provide personalized financial planning and
                  tax integration services.
               </p>
            </section>

            <section
               className={['section', styles.section, styles.section__two].join(
                  ' '
               )}>
               <div className={styles.mission}>
                  <div
                     className={[
                        'section--header',
                        styles.section__header,
                     ].join(' ')}>
                     <h2
                        className={[
                           'section--title',
                           styles.section__title,
                        ].join(' ')}>
                        Our Journey
                     </h2>
                  </div>
                  <p>
                     The past four decades have seen changes in both income and
                     estate tax laws, as well as multiple changes in investment
                     products. Clients have relied on SPC to help guide them
                     through these challenges while keeping them focused on
                     their individual goals and objectives.
                  </p>
               </div>
               <div className={styles.vision}>
                  <div
                     className={[
                        'section--header',
                        styles.section__header,
                     ].join(' ')}>
                     <h2
                        className={[
                           'section--title',
                           styles.section__title,
                        ].join(' ')}>
                        Our Philosophy
                     </h2>
                  </div>
                  <p>
                     It is our belief, individuals are often challenged in
                     achieving long term financial objectives, as a result of
                     volatile investment environments, changing income, estate
                     tax laws, and potential complexities of employer provided
                     benefits.
                  </p>
               </div>
            </section>
         </div>
      </div>
   </Layout>
)

export default AboutPage
